import {contacts} from './tableModules/contacts'
import {loginProviders} from './tableModules/loginProviders'
import {organisations} from "./tableModules/organisations"
import {users} from './tableModules/users'
import {mailTemplates} from "~/utils/tableModules/mailTemplates"

export const tableModules: TableModules = {
    users,
    organisations,
    testUsers: {
        ...users,
        name: 'testUsers'
    } as TableModule,
    contacts,
    loginProviders,
    mailTemplates
}
